<template>
  <OneColumnTemplate>
    <template v-slot:ColumnContent>
      <div class="PageContentWrapper">
        <div class="Page-Content" v-if="post != null">
          <OUIButtonTertary
            class="BackButton"
            style="margin-bottom: -30px;"
            ButtonText="Назад"
            :SendCallback="goBack"
          >
            <template v-slot:LeftIcon>
              <div class="BackImage" />
            </template>
          </OUIButtonTertary>
          <span class="NB-Date">{{ getFormatedDate(post.createdAt) }}</span>

          <div class="NP-Full-Info ql-snow">
            <span class="NP-Header">{{ post.title }}</span>
            <span class="NP-Full-Desc ql-editor" v-html="post.desc"> </span>

            <div class="NP-Buttons" v-show="post.cta_link != null">
              <a :href="post.cta_link" target="_blank" class="BlueButton">{{
                post.cta_name
              }}</a>
            </div>
          </div>

          <div class="PC-Media" v-if="post.NewsMaterials.length != 0">
            <div
              class="PC-M-Block"
              v-for="(mat, mindx) in post.NewsMaterials"
              :key="`material-${mindx}-${mat.url_file}`"
            >
              <template v-if="getFileType(mat.url_file) === 'img'">
                <img
                  :src="mat.url_file"
                  :alt="getFilenameWithExt(mat.url_file)"
                  @click="showPhoto(mat.url_file)"
                />
              </template>

              <template v-else-if="getFileType(mat.url_file) === 'video'">
                <VideoThumbnail
                  @click.native="
                    () => {
                      if (test) {
                        showVideo(mat.url_file);
                      } else {
                        openTabWithVideo(mat.url_file);
                      }
                    }
                  "
                  class="VideoThumb"
                  :options="{
                    preload: 'auto',
                    volume: 0,
                    autoplay: false,
                    controls: false,
                    sources: [
                      {
                        src: mat.url_file,
                        type: `video/${getFileExt(mat.url_file)}`,
                      },
                    ],
                  }"
                />
              </template>

              <template v-else-if="getFileType(mat.url_file) === 'file'">
                <a :href="mat.url_file" target="_blank">
                  <span>{{ getFilenameWithExt(mat.url_file) }}</span>
                </a>
              </template>
            </div>
          </div>
        </div>

        <NewStyledModal
          class="Styled-Modal"
          :showModal="show_modal"
          :hideModal="
            () => {
              show_modal = false;
            }
          "
          :key="show_modal"
        >
          <template v-slot:ModalContent>
            <div class="Offer-Modal">
              <span class="Offer-Modal-Title">
                Просмотр материала
              </span>
              <div class="Offer-Modal-Content">
                <img v-show="show_photo" :src="show_src" alt="Изображение" />
                <video
                  v-show="!show_photo"
                  ref="ModalVideoPlayer"
                  class="video-js"
                  :muted="true"
                  :loop="false"
                  controls
                ></video>
              </div>
              <div class="Offer-Modal-Buttons noselect">
                <span
                  class="Offer-Button-Prev"
                  @click="
                    () => {
                      show_modal = false;
                    }
                  "
                  >Закрыть</span
                >
              </div>
            </div>
          </template>
        </NewStyledModal>
      </div>
    </template>
  </OneColumnTemplate>
</template>

<script>
import OneColumnTemplate from "@/components/ProfileTemplates/OneColumn.vue";
import VideoThumbnail from "@/components/CustomElements/VideoThumbnail.vue";
import NewStyledModal from "@/components/CustomElements/NewStyledModal.vue";

import OUIButtonTertary from "@/components/OctaUI/Buttons/tertary.vue";

import videojs from "video.js";
import { postDetails } from "@/api/news.js";

export default {
  name: "NewsDetail",
  components: {
    OneColumnTemplate,
    VideoThumbnail,
    NewStyledModal,
    OUIButtonTertary,
  },
  data() {
    return {
      post: null,
      show_modal: false,
      show_photo: true,
      show_src: null,
      player: null,

      test: false,
    };
  },
  async created() {
    const postName = this.$route.query?.post;
    if (postName == null || postName == "") {
      this.$router.push("/beta/news-list");
    }

    let _post_req = await postDetails(postName);
    if (_post_req.data?.status === "error") {
      this.$router.push("/beta/news-list");
    }

    this.post = _post_req.data;

    if (this.$route.query?.test != null && this.$route.query?.test != "") {
      this.test = true;
    }
  },
  methods: {
    goBack() {
      if (window.history.state.back != null) {
        // Если в истории есть страницы, идём назад
        this.$router.go(-1);
      } else {
        // Иначе перенаправляем на указанную страницу
        this.$router.push({ name: "BetaProfile" }); // Замените 'HomePage' на имя вашего роута
      }
    },

    getMonthName(month) {
      const monthNames = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ];
      return monthNames[month];
    },
    getFormatedDate(createdAt) {
      const originalDate = new Date(createdAt);

      // Преобразование даты в нужный формат
      const day = originalDate.getDate();
      const month = this.getMonthName(originalDate.getMonth());
      const year = originalDate.getFullYear();

      return `${day} ${month} ${year}`;
    },
    getFilenameWithExt(filePath) {
      let getCleanFileNameWithExtension = (filePath) => {
        const parts = filePath.split(/[\\\/]/);
        const fileNameWithExtension = parts[parts.length - 1];
        const fileNameWithoutNumbersBeforeDash = fileNameWithExtension.replace(
          /^\d+-/,
          ""
        );
        return fileNameWithoutNumbersBeforeDash;
      };

      return getCleanFileNameWithExtension(filePath);
    },
    getFileType(filePath) {
      if (filePath == null) {
        return "unknown";
      }

      let full_file_name = this.getFilenameWithExt(filePath);
      let _file_ext = full_file_name.split(".");
      let type = _file_ext[_file_ext.length - 1];
      switch (type) {
        case "webm": {
        }
        case "mp4": {
          return "video";
        }
        case "jpg": {
        }
        case "jpeg": {
        }
        case "webp": {
        }
        case "png": {
          return "img";
        }
        default: {
          return "file";
        }
      }
    },
    getFileExt(filePath) {
      if (filePath == null) {
        return "unknown";
      }

      let full_file_name = this.getFilenameWithExt(filePath);
      let _file_ext = full_file_name.split(".");
      let type = _file_ext[_file_ext.length - 1];
      return type;
    },
    showPhoto(url) {
      this.show_src = url;
      this.show_photo = true;
      this.show_modal = true;
    },
    showVideo(url) {
      this.show_src = url;
      this.show_photo = false;

      this.show_modal = true;

      this.player.src(url);

      // this.player = videojs(
      //   this.$refs.ModalVideoPlayer,
      //   {
      //     preload: "auto",
      //     volume: 0,
      //     autoplay: false,
      //     controls: true,
      //     sources: [
      //       {
      //         src: url,
      //         type: `video/${this.getFileExt(url)}`,
      //       },
      //     ],
      //   },
      //   () => {
      //     this.player.volume(0);
      //   }
      // );
    },

    openTabWithVideo(url) {
      window.open(url, "_blank");
    },
  },
  computed: {},
  mounted() {
    this.player = videojs(this.$refs.ModalVideoPlayer, {
      volume: 0,
      autoplay: false,
      controls: true,
      sources: [],
    });
  },
};
</script>

<style scoped>
.PageContentWrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 44px;

  width: 100%;
  height: 100%;

  padding-right: 15px;

  overflow: auto;
}
.Page-Content {
  position: relative;
  display: flex;

  margin: 0% auto;

  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;

  width: 100%;
  max-width: 1150px;
  height: fit-content;
}

.NB-Date {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;

  color: #d3caffb2;
}

.BlueButton {
  position: relative;
  display: block;

  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #ffffffe5;

  transition: 0.4s;

  border-radius: 4px;
  background-color: #656bff;
}
.BlueButton:hover {
  opacity: 0.8;
}

.NP-Full-Info {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  width: 100%;
  height: fit-content;
}
.NP-Header {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;
  color: white;
}
.NP-Full-Desc {
  position: relative;
  display: block;

  padding: 0% !important;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #ffffffe5;
}

.NP-Buttons {
  position: relative;
  display: flex;

  justify-content: flex-start;
  flex-direction: row;
  gap: 8px;
}

.PC-Media {
  position: relative;
  display: grid;

  grid-template-columns: repeat(3, calc((100% - (24px * 2)) / 3));
  gap: 24px;

  width: 100%;
  height: fit-content;
}
.PC-M-Block {
  position: relative;
  display: flex;

  cursor: pointer;

  width: 100%;
  height: auto;

  aspect-ratio: 1 / 0.5625;

  transition: 0.4s;

  background-color: rgb(48, 46, 81);

  overflow: hidden;
  border-radius: var(--o-s-global-border-radius);
}
.PC-M-Block > img {
  position: relative;

  margin: auto;

  width: 100%;
  height: auto;
}

.PC-M-Block > a {
  position: relative;
  display: flex;

  padding: 12px;

  width: 100%;
  height: 100%;

  text-decoration: none;
}
.PC-M-Block > a > span {
  position: relative;
  display: flex;

  margin: auto;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: white;
}
.PC-M-Block:hover {
  opacity: 0.7;
}

.VideoThumb {
  position: relative;
  display: block;

  width: 100%;
  height: 100%;

  overflow: hidden;
}
.VideoThumb:deep(.video-js) {
  width: 100%;
  height: 100%;
}

@media (max-width: 992px) {
  .PageContentWrapper {
    padding-bottom: 70px;
  }

  .PC-Media {
    grid-template-columns: repeat(1, 100%);
  }
}
</style>

<style scoped>
.Styled-Modal:deep(.SendRequestModal) {
  min-width: 320px;
  max-width: 90svw;
  width: fit-content;

  height: fit-content;
  max-height: 90svh;

  background: #1e1b4a;
}
.Offer-Modal {
  position: relative;
  display: flex;

  padding-top: 12px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: auto;
  height: fit-content;
}

.Offer-Modal-Title {
  position: relative;
  display: block;

  min-width: 320px;
  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;

  color: #ffffffe5;
}
.Offer-Modal-Content {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  min-width: 320px;
  width: auto;
  height: fit-content;
}
.Offer-Modal-Buttons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  min-width: 320px;
  width: auto;
  height: fit-content;
}
.Offer-Button-Prev {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  flex-shrink: 0;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff40;
}
.Offer-Button-Prev:hover {
  background-color: rgba(101, 106, 255, 0.144);
}

.BackImage {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../../assets/img/ReDesign/interface_icons/arrow_up.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;

  rotate: -90deg;
}

.BackButton:not(.Disabled):active > .BackImage {
  filter: brightness(0) saturate(100%) invert(42%) sepia(50%) saturate(4871%)
    hue-rotate(224deg) brightness(103%) contrast(101%);
}

.BackButton.Disabled > .BackImage {
  filter: brightness(0) saturate(100%) invert(26%) sepia(53%) saturate(560%)
    hue-rotate(204deg) brightness(93%) contrast(84%);
}
</style>
